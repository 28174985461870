import { StrapiProduct } from "~/shared-types";
import dynamic from "next/dynamic";

import { ProductCard } from "../ProductCard";
import { useMediaQuery } from "~/hooks/useMediaQuery";
import { Text } from "../Text";
import { usePagination } from "./usePagination";

import "./style.scss";
import { useEffect, useState } from "react";

const Layout = dynamic(() => import("react-masonry-list"), {
  ssr: false,
});

type Props = {
  products: StrapiProduct[];
  className?: string;
};

export const ProductsMasonry = ({ products, className }: Props) => {
  const isDesktop = useMediaQuery("(min-width: 768px)");

  const { currentItems, PaginationNavigation } = usePagination(products);
  const [loadedImages, setLoadedImages] = useState(0);
  const [layoutKey, setLayoutKey] = useState(0);
  const handleImageLoad = () => {
    setLoadedImages((count) => count + 1);
  };

  // Trigger masonry re-layout when all images are loaded
  useEffect(() => {
    // Wait for all images in the products to load
    if (loadedImages === products.length) {
      setLayoutKey((prevKey) => prevKey + 1);
    }
  }, [loadedImages, products.length]);

  if (products.length === 0) return <Text>Ingen produkter at vise.</Text>;

  return (
    <div>
      <Layout
        key={layoutKey}
        className={className + " ProductsMasonry"}
        minWidth={100}
        colCount={isDesktop ? 4 : 2}
        gap={20}
        items={(products).map((product) => (
          <ProductCard
            key={product.id}
            className="mb-4 md:mb-8"
            product={product}
            onImageLoad={handleImageLoad}
          />
        ))}
      />
    </div>
  );
};
